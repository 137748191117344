import styled from "styled-components";
import xlogo from '../../assets/Xlogo.png';
import red from '../../assets/rm-logo.png';

import { Card1 } from "components/Form/Card1";
import Heading from "components/Form/Heading";
import colors from "styles/colors";
import { ReactNode } from "react";

const Header = styled(Card1)`
  margin: 0.2rem auto;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
  width: 95vw;
  @media (max-width: 761px) {
  display: flex;
  flex-direction: column;

}
  
`;

const Nav = (props: { children?: ReactNode }) => {
  return (
    <Header as="header">
      <div style={{ textAlign: "center", display:'flex'}}>
        <a href="/">
          <img width="150" src={red} alt="Redmorph Icon" />
        </a>
        <div style={{ height: '30px', width: '1px', backgroundColor: 'black', marginTop:'1rem'}}></div>
        <img width="55" height='45' src={xlogo} alt="Redmorph Icon" style={{marginTop:'0.7rem',paddingLeft:'10px'}}/>
      </div>
      {/* <Heading color={colors.primary} size="large">
      <img width="64" src="/web-check.png" alt="Web Check Icon" />
      <a href="/">Web Check</a>
      <a href="/">Redmorph X</a>
    </Heading> */}
      {props.children && props.children}
    </Header>
  );
};

export default Nav;
