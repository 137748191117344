import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import OnboardingOne from "./OnbordingComp/OnboardingOne";
import OnboardingTwo from "./OnbordingComp/OnboardingTwo";
import OnboardingThree from "./OnbordingComp/OnboardingThree";
import ResultsComponent from "./Results";
import "../styles/AnimationComponent.css";

const Final: React.FC = () => {
  const location = useLocation();
  const { scanId, domainName, addressType } = location.state || {};

  const [mtrResult, setMtrResult] = useState<any>(null);
  const [crawlResult, setCrawlResult] = useState<any>(null);
  const [results, setResults] = useState<any>(null);
  const [isBlurred, setIsBlurred] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const api = process.env.REACT_APP_API_ENDPOINT || "/api";

  useEffect(() => {
    const fetchData = async () => {
      if (!domainName) {
        return;
      }

      try {
        const traceRoutePromise = fetch(
          `${api}/trace-route?url=${domainName}`
        ).then((res) => res.json());

        const crawlPromise = fetch(`${api}/crawl?url=${domainName}`).then(
          (res) => res.json()
        );

        const [traceRouteResult, crawlResult] = await Promise.all([
          traceRoutePromise,
          crawlPromise,
        ]);

        // console.log("Traceroute Result: ", traceRouteResult);
        // console.log("Crawl Result: ", crawlResult);

        setMtrResult(traceRouteResult);
        setCrawlResult(crawlResult);

        localStorage.setItem("crawlResult", JSON.stringify(crawlResult));
        localStorage.setItem("mtrResult", JSON.stringify(traceRouteResult));

        setResults(crawlResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [domainName, api]);

  const goToNextStep = () => {
    if (currentStep < 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setOnboardingComplete(true);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <OnboardingOne
            scanId={scanId}
            domainName={domainName}
            addressType={addressType}
            mtrResult={mtrResult}
            loading={!mtrResult}
            onComplete={goToNextStep}
            isBlurred={isBlurred}
          />
        );
      case 1:
        const storedCrawlResult = JSON.parse(
          localStorage.getItem("crawlResult") || "null"
        );
        return (
          <OnboardingTwo
            scanId={scanId}
            domainName={domainName}
            addressType={addressType}
            crawlResult={storedCrawlResult}
            loading={!storedCrawlResult}
            onComplete={goToNextStep}
            isBlurred={isBlurred}
          />
        );
      // case 2:
      //   return (
      //     <OnboardingThree
      //       scanId={scanId}
      //       domainName={domainName}
      //       addressType={addressType}
      //       onComplete={goToNextStep}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={currentStep} timeout={2000} classNames="slide">
        <>
          {!onboardingComplete ? (
            renderCurrentStep()
          ) : results ? (
            <ResultsComponent
              results={results}
              scanId={scanId}
              domainName={domainName}
            />
          ) : (
            <div>Loading results...</div>
          )}
        </>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Final;
