import styled from 'styled-components';

import ErrorBoundary from 'components/misc/ErrorBoundary';
import Heading from 'components/Form/Heading';
import colors from 'styles/colors';
import { ReactNode } from 'react';

export const StyledCard = styled.section<{ styles?: string}>`
  background: ${colors.backgroundLighter};
  padding: 0.5rem;
  position: relative;
  margin 0.5rem;
  max-height: 75rem;
  min-width: 35vw;
  overflow: auto;
  font-family: 'Orbitron';
  overflow:hidden;
  ${props => props.styles}
   @media (max-width: 480px) {
  display: flex;
  flex-direction: column;
  min-width: 40vw;
}

@media (max-width: 768px) {
  display: flex;
  flex-direction: column;
  min-width: 40vw;
}

@media (max-width: 460px) {
  display: flex;
  flex-direction: column;
  min-width: 40vw;
}


  @media (max-width: 1000px) {
  display: flex;
  flex-direction: column;
  min-width: 38vw;
}
`;

// const StyledHr = styled.hr`
//   border: none;
//   border-top: 2px solid #FF8B00; // Customize thickness and color
//   margin-top: 0.5rem;
//   margin-bottom: 0.5rem;
//    @media (max-width: 480px) {

//   border: none;
//   border-top: 2px solid #FF8B00; // Customize thickness and color
//   margin-top: 0.5rem;
//   margin-bottom: 0.5rem;
// }

// @media (max-width: 768px) {
//   border: none;
//   border-top: 2px solid #FF8B00; // Customize thickness and color
//   margin-top: 0.5rem;
//   margin-bottom: 0.5rem;
// }
// `;
const StyledHr = styled.hr`
  border: none;
  border-top: 2px solid #FF8B00; // Customize thickness and color
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: calc(100% + 2rem); // Adjust to account for padding
  margin-left: -1rem; // Offset padding on the left
  margin-right: -1rem; // Offset padding on the right
`;
const Spacing = styled.div`
  margin-top:1rem;
@media (max-width:1450px){
  margin-top:2rem;
}
`;
interface CardProps {
  children: React.ReactNode;
  heading?: string,
  styles?: string;
  actionButtons?: ReactNode | undefined;
  icon?: any;
};

export const MapCard = (props: CardProps): JSX.Element => {
  const { children, heading, styles, actionButtons, icon } = props;
  return (
    <ErrorBoundary title={heading}>
      <StyledCard styles={styles}>
        { actionButtons && actionButtons }
        <Spacing>
        { heading && <Heading className="inner-heading" as="h4" color={colors.primary} icon={icon}>{heading}</Heading> 
        }
         <StyledHr/>
         </Spacing>
        {children}
      </StyledCard>
    </ErrorBoundary>
  );
}

export default StyledCard;
