import { ChangeEvent, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { determineAddressType } from "utils/address-type-checker";
import "./Scanner.css";

const Home = (): JSX.Element => {
  const [userInput, setUserInput] = useState("");
  const [errorMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();  // To capture navigation state
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const sessionToken = getSessionTokenFromCookie();
    if (sessionToken) {
      submit();
    } else {
      fetchSessionToken();
    }
    
    // If navigating back from Final.tsx, clear domainName if passed in state
    if (location.state && location.state.clearInput) {
      setUserInput(""); 
      localStorage.removeItem('scanData'); // Reset input on navigating back
    }
  }, [location]);

  const getSessionTokenFromCookie = () => {
    const { cookie } = document;
    const sessionTokenCookie = cookie
      .split(";")
      .find((c) => c.trim().startsWith("session_token="));
    if (sessionTokenCookie) {
      return sessionTokenCookie.split("=")[1];
    }
    return null;
  };

  const fetchSessionToken = async () => {
    try {
      const sessionToken = getSessionTokenFromCookie();
      if (!sessionToken) {
        throw Error("No existing session token found.");
      }
    } catch (error) {
      // console.log("Error fetching session token:", error);
    }
  };

  const submit = async () => {
    let address = userInput.endsWith("/") ? userInput.slice(0, -1) : userInput;
    const addressType = determineAddressType(address);

    if (addressType === "empt" || addressType === "err") {
      setErrMsg("Please enter a valid URL to scan");
    } else {
      if (addressType === "url" && !/^https?:\/\//i.test(address)) {
        address = "https://" + address;
      }

      await startScan(address, addressType);
      setUserInput('');  // Clear input after submission
    }
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  const startSequentialScans = async (address: string, addressType: string) => {
    try {
      const api = process.env.REACT_APP_API_ENDPOINT || "/api"; 

      const apiResponse = await fetch(`${api}/startScan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address }),
      });

      const apiData = await apiResponse.json();

      const mtrData = {
        message: "MTR Traceroute completed!",
        result: [
          {
            hopNumber: "1",
            host: "192.168.1.1",
            packetLoss: "0.0",
            sentPackets: "10",
            lastTime: "1.9",
            avgTime: "5.0",
            bestTime: "1.4",
            worstTime: "30.2",
            stdDev: "9.0",
            ipInfo: {
              status: "fail",
              message: "private range",
              query: "192.168.1.1",
            },
          },
        ],
      };

      if (mtrData.message === "MTR Traceroute completed!" && mtrData.result) {
        return {
          apiScanId: apiData.scanId,
          mtrResult: mtrData.result,
        };
      } else {
        throw new Error("Failed to retrieve MTR scan result.");
      }
    } catch (error) {
      console.error("Error during sequential scans:", error);
      throw new Error("Failed to communicate with the server for scans.");
    }
  };

  const startScan = async (address: string, addressType: string) => {
    try {
      setLoading(true);
      const scanData = await startSequentialScans(address, addressType);

      if (scanData.apiScanId && scanData.mtrResult) {
        localStorage.setItem('scanData', JSON.stringify(scanData)); 
        navigate(`/results/${scanData.apiScanId}`, {
          state: {
            clearInput: true, // Flag to clear input on back navigation
            scanId: scanData.apiScanId,
            domainName: address,
            addressType: addressType,
          },
        });
      } else {
        setErrMsg("Failed to start scan. Please try again.");
      }
    } catch (error) {
      console.error("Error starting the scan:", error);
      setErrMsg("Failed to communicate with the server. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
    const isError = ["err", "empt"].includes(
      determineAddressType(event.target.value)
    );
    if (!isError) {
      setErrMsg("");   

    }
  };

  return (
    <div className="scanner-container">
      <img width="256px" src="/rm-logo.png" alt="Redmorph Icon" />
      <h2 className="title">
        Ultimate scanner for consolidated website profile and threat vectors
        data
      </h2>
      <div className="search-box">
        <input
          type="text"
          placeholder="Please enter URL to Scan"
          value={userInput}
          onChange={inputChange}
          onKeyDown={handleKeyDown}
        />
        <button className="search-icon" onClick={submit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21   
 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>   

      <button className="search-button" onClick={submit}>
        Search
      </button>
      <div className="error">{errorMsg && <p className="error-message">{errorMsg}</p>}</div>
    </div>
  );
};

export default Home;