// import CarbonCard from "components/Results/CarbonFootprint";
import React, { useEffect } from "react";
import  './network.css';
// import { useLocation } from "react-router-dom";
// import * as anychart from "anychart";
// import AnyChart as anychart from "anychart-react.min.js";
// Try this instead of direct import
const anychart = require("anychart");

interface Node {
  id: any;
  value: number;
  group: string;
  ipInfo?: any; // Optional if not always present
}
interface NetorkGraphProps {
  domainName: string; // Type can be adjusted
  crawlResult:any;
}

const NetworkGraph: React.FC<NetorkGraphProps> = ({crawlResult}) => {
  // const location = useLocation();
  // const {domainName} = location.state
  // const api = process.env.REACT_APP_API_ENDPOINT || "/api";
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve the 'scanParams' from localStorage
        // const storedScanParams = localStorage.getItem("scanParams");
        // let apiUrl = "";

        // if (storedScanParams) {
        //   const { address } = JSON.parse(storedScanParams); // Extract the URL address
          //apiUrl = `${api}/crawl?url=${domainName}`; // Construct the API URL with the address
        // }

        // Make the API call using the constructed URL
        // const response = await fetch(`${api}/crawl?url=${domainName}`);
        const data = crawlResult
        // console.log(data);

        // Extract the relevant data from the fetched data
        const targetURL = data.targetURL; // Main domain
        // console.log(targetURL);
        const externalPages = data.externalPages; // External pages
        // const internalPages = data.internalPages; // Internal pages

        // Create nodes array
        const nodes = [
          { id: targetURL, value: 100000, group: "main" }, // Main target node
        ];

        // Add external pages as nodes
        externalPages.forEach(
          (page: { url: any; resolvedIP: any }, index: number) => {
            const node: Node = {
              id: page.url,
              value: 10000 + index * 5000,
              group: "external",
              ipInfo: page.resolvedIP || "IP Unavailable",
            };
            nodes.push(node);
          }
        );

        // Add internal pages as nodes
        // internalPages.forEach((page, index) => {
        //   nodes.push({
        //     id: page.url,
        //     value: 5000 + index * 2000, // Assign a smaller value for internal nodes
        //     group: "internal",
        //     ipInfo: page.ipInfo?.query || "IP Unavailable", // Use optional chaining to avoid errors
        //   });
        // });

        // Create edges array
        const edges: { from: any; to: any }[] = [];

        // Connect target URL to external pages
        externalPages.forEach((page: { url: any }) => {
          edges.push({
            from: targetURL,
            to: page.url,
          });
        });

        // Connect internal pages to the target URL
        // internalPages.forEach(page => {
        //   edges.push({
        //     from: targetURL,
        //     to: page.url,
        //   });
        // });

        // Define data for the graph
        const graphData = { nodes, edges };

        // Create the graph chart and set the data
        const chart = anychart.graph(graphData);

        // Prevent zooming with the mouse wheel
        chart.interactivity().zoomOnMouseWheel(true);

        // Set node size based on value
        chart
          .nodes()
          .normal()
          .height(function (this: any) {
            // Define the type of 'this' here
            return this.get("value") / 5000;
          });

        // Enable labels of nodes and configure labels
        chart.nodes().labels().enabled(true);
        chart.nodes().labels().format("{%id}");
        chart.nodes().labels().fontSize(12);
        chart.nodes().labels().fontWeight(600);
        chart.nodes().labels().fontFamily("Orbitron, sans-serif");

        // Add tooltips to display IP info on hover
        chart
          .nodes()
          .tooltip()
          .format(function (this: any) {
            return `IP: ${this.getData("ipInfo")}`;
          });

        // Set layout algorithm to force-based
        chart.layout().type("force");

        // Configure group colors for different domains and external pages
        chart.group("main").fill("#3498db");
        chart.group("external").fill("#e74c3c");
        //chart.group("internal").fill("#f39c12"); // Color for internal pages

        // Set container id and draw the chart
        chart.container("networkGraphContainer");
        chart.draw();

        return () => {
          chart.dispose();
        };
      } catch (error) {
        console.error("Error fetching network graph data:", error);
      }
    };

    fetchData();
  }, []);

  const externalPages = crawlResult?.externalPages || [];

  return (
    <div>
       {externalPages.length > 0 ? (
        <div
          id="networkGraphContainer"
          style={{ width: "100%", height: "75vh", fontFamily: "Orbitron" }}
        ></div>
      ) : (
        <p style={{ textAlign: "center", padding:"20vh 0", color: "red", fontSize:"1rem", fontWeight: "bold" }}>
          {/* Crawl data is unavailable */}
        </p>
      )}
    </div>
  );
};

export default NetworkGraph;
