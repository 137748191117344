import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { geoMercator, geoPath } from "d3-geo";
import styled from "styled-components";
import { feature } from "topojson-client";
import { FeatureCollection } from "geojson";
//import "../../styles/OnbordingOne.css"; // Assuming you need this for additional styles
import gpsLogo from "../../assets/Gpsloc.png";
import serverpack from "../../assets/img/serverpack.png";
import "../../styles/Maproute.css";
// Define the types for locations
interface Location {
  latitude: number;
  longitude: number;
}

const MapRoute: React.FC<{
  mtrResult: any; // The API result
  loading: boolean; // The loading status
}> = ({ mtrResult, loading }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    if (!mtrResult || loading) {
      return;
    }

    // Extract latitude and longitude information from mtrResult
    if (mtrResult.result && mtrResult.result.length > 0) {
      const mappedLocations = mtrResult.result
        .map((loc: any) => {
          const geoInfo = loc.geoInfo;
          if (geoInfo && !geoInfo.error && geoInfo.latitude !== "N/A" && 
            geoInfo.longitude !== "N/A") {
            return {
              latitude: geoInfo.latitude,
              longitude: geoInfo.longitude,
            };
          }
          return null;
        })
        .filter((loc: any) => loc !== null);

      setLocations(mappedLocations);
    }
  }, [loading, mtrResult]);

  useEffect(() => {
    if (locations.length === 0) return;

    const width = 600;
    const height = 500;

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    const projection = geoMercator()
      .scale(100)
      .translate([width / 2, height / 1.3]);
    const path = geoPath().projection(projection);

    svg.selectAll("*").remove();

    d3.json("https://unpkg.com/world-atlas/countries-50m.json").then(
      (data: any) => {
        const countries = feature(
          data,
          data.objects.countries
        ) as unknown as FeatureCollection;

        svg
          .selectAll(".country")
          .data(countries.features)
          .enter()
          .append("path")
          .attr("class", "country")
          .attr("d", path as any)
          .attr("fill", "#075283")
          .attr("stroke", "#fff")
          .attr("stroke-width", 0.5);

        const locationsGroup = svg.append("g").attr("class", "locations");
        locations.forEach((location,i) => {
          const [x, y] = projection([
            location.longitude,
            location.latitude,
          ]) as [number, number];
           // Check if it's the starting point
      if (i === 0) {
        locationsGroup
          .append("image")
          .attr("xlink:href", gpsLogo) // Use startingPointIcon
          .attr("x", x - 12)
          .attr("y", y - 12)
          .attr("width", 20)
          .attr("height", 20);
      }
      // Check if it's the ending point
      else if (i === locations.length - 1) {
        locationsGroup
          .append("image")
          .attr("xlink:href", serverpack) // Use endingPointIcon
          .attr("x", x - 12)
          .attr("y", y - 12)
          .attr("width", 20)
          .attr("height", 20);
      }
      // For intermediate points
      else {
        locationsGroup
          .append("image")
          .attr("xlink:href", serverpack) // Use default gpsLogo
          .attr("x", x - 12)
          .attr("y", y - 12)
          .attr("width", 20)
          .attr("height", 20);
      }
    });

        // Create paths (arcs) between locations
        const linePaths: d3.Selection<
          SVGPathElement,
          unknown,
          SVGSVGElement,
          unknown
        >[] = [];
        locations.forEach((location, i) => {
          if (i < locations.length - 1) {
            const source = location;
            const target = locations[i + 1];

            // Use a custom arc generator to create rounder arcs
            const arcGenerator = (
              source: [number, number],
              target: [number, number]
            ) => {
              const dx = target[0] - source[0];
              const dy = target[1] - source[1];
              const dr = Math.sqrt(dx * dx + dy * dy);
              return `M${source[0]},${source[1]}A${dr},${dr} 0 0,1 ${target[0]},${target[1]}`;
            };

            const sourcePos = projection([
              source.longitude,
              source.latitude,
            ]) as [number, number];
            const targetPos = projection([
              target.longitude,
              target.latitude,
            ]) as [number, number];

            const linePath = svg
              .append("path")
              .attr("class", "arc")
              .attr("d", arcGenerator(sourcePos, targetPos))
              .attr("fill", "none")
              .attr("stroke", "#FF3131")
              .attr("stroke-width", 1.8)
              .attr("stroke-dasharray", "5,5");

            linePaths.push(linePath as any);
          }
        });

        // Animate the plane along the arcs
        if (linePaths.length > 0) {
          const planeImage = svg
            .append("image")
            .attr("xlink:href", serverpack)
            .attr("width", 30)
            .attr("height", 30)
            .attr("opacity", 0);

          const animateAlongPath = (pathIndex: number) => {
            const linePath = linePaths[pathIndex];
            const totalLength = linePath.node()?.getTotalLength() ?? 0;

            planeImage
              .attr("opacity", 1)
              .attr("transform", `translate(-15, -15)`);

            planeImage
              .transition()
              .duration(1500)
              .attrTween("transform", function () {
                return function (t: number) {
                  const point = linePath
                    .node()
                    ?.getPointAtLength(t * totalLength);
                  return point
                    ? `translate(${point.x - 15}, ${point.y - 15})`
                    : "";
                };
              })
              .ease(d3.easeLinear)
              .on("end", () => {
                if (pathIndex < linePaths.length - 1) {
                  animateAlongPath(pathIndex + 1);
                } else {
                  planeImage.transition().duration(500).attr("opacity", 0);
                }
              });
          };

          animateAlongPath(0);
        }
      }
    );
  }, [locations, gpsLogo, serverpack]);

  return (
    <>
      <svg ref={svgRef} className="map" preserveAspectRatio="xMidYMid meet" />
      <LegendContainer>
        <LegendTitle>Legends</LegendTitle>
        <LegendItem>
          <LegendIcon src={gpsLogo} alt="Your Location" />
          <LegendText>Your Location</LegendText>
          <VLine />
          <LegendIcon src={serverpack} alt="Server Location" />
          <LegendText>Server Location</LegendText>
        </LegendItem>
      </LegendContainer>
    </>
  );
};

export default MapRoute;


const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position:relative;
  // margin-top: 1rem;
  margin-left: 25%;
  // padding: 1rem;
  // background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 300px;

  @media (max-width: 1100px) {
      margin-left:35%
    }

    @media (max-width: 768px) {
      margin-left:30%
    }

  @media (max-width: 550px) {
      margin-left:15%
    }

`;

const LegendTitle = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LegendIcon = styled.img`
  width: 15px;
  height: 15px;
  // margin-right: 0.5rem;
`;

const LegendText = styled.div`
  font-size: 0.6rem;
  margin-right: 0.5rem;
  letter-spacing: 0.8px;
`;

const VLine = styled.div`
  height: 24px;
  width: 1px;
  background-color: #ccc;
  margin: 0 0.5rem;
`;