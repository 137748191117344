import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import "../../styles/OnboardingTwo.css";
import styled from "styled-components";
import * as Flags from "country-flag-icons/react/3x2";
import NetworkGraph from "../NetworkGraphComp/NetworkGraph";

type CountryCode = keyof typeof Flags;

// Function to dynamically get the country flag component based on country code
const getCountryFlagComponent = (countryCode: string | number) => {
  if (
    typeof countryCode === "string" &&
    (countryCode as CountryCode) in Flags
  ) {
    const FlagIcon = Flags[countryCode as CountryCode];
    return <FlagIcon style={{ width: "30px", height: "20px" }} />;
  } else {
    return <span>🏳️</span>; // Default to neutral flag if country code is not found
  }
};

interface OnboardingTwoProps {
  scanId: string;
  domainName: string;
  addressType: string;
  crawlResult: any;
  loading: boolean;
  isBlurred: boolean;
  onComplete: () => void; // Add this line
}

const WarningModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it appears above other content */
`;

const ModalContent = styled.div`
  background: white;
  font-family: "Orbitron", sans-serif;
  padding-top: 30px;
  padding-bottom: 30px;
  padding: 30px;
  box-shadow: 0px 1px 10px 5px #075283;
  border-radius: 8px;
  width: 30vw;
  text-align: center;

  @media (max-width: 480px) {
    width: 90vw; /* Even more responsive for very small screens */
    padding: 15px;
  }

  @media (orientation: landscape) and (max-width: 768px) {
    width: 40vw; /* Adjust width for landscape */
  }
`;

const ModalHeading = styled.h2`
  margin: 0 0 15px;
  font-size: 2rem;
  letter-spacing: 3px;

   @media (max-width: 768px) {
    font-size: 1.4rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Further adjust for smaller screens */
  }
`;

const ModalMessage = styled.p`
  margin: 0 0 20px; /* Margin below the message */
  letter-spacing: 2px;

   @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Further adjust for smaller screens */
  }
`;

const OnbordingTwo: React.FC<OnboardingTwoProps> = ({
  scanId,
  domainName,
  addressType,
  crawlResult,
  loading,
  isBlurred,
  onComplete,
}) => {
  const [ipInfoData, setIpInfoData] = useState<any[]>([]);
  const [targetURL, setTargetURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  // const location = useLocation();
  // const navigate = useNavigate();
  // console.log(scanId, domainName, addressType);

  // }

  // const OnbordingTwo: React.FC<OnboardingTwoProps> = ({ scanId, domainName, addressType, crawlResult, loading }) => {
  //   const [ipInfoData, setIpInfoData] = useState<any[]>([]);
  //   const [targetURL, setTargetURL] = useState("");
  //   const [isLoading, setIsLoading] = useState(true);
  //   // const location = useLocation();
  //   // const navigate = useNavigate();
  //   console.log(scanId, domainName, addressType);

  //   // const api = process.env.REACT_APP_API_ENDPOINT || "/api";
  // >>>>>>> bug-fixes-firstround

  useEffect(() => {
    const fetchIpInfoData = async () => {
      try {
        // Assuming crawlResult is correctly passed down
        const data = crawlResult;
        // console.log(data);

        // Ensure that crawlResult contains the expected structure
        if (!data || !data.allUrls || data.allUrls.length === 0) {
          // console.error("Invalid crawl result structure:", data);
          setIsLoading(false);
          setShowWarning(true);
          return;
        }

        setTargetURL(data.targetURL || "No URL Found");

        const mappedIpInfoData = data.allUrls.map((page: any) => ({
          ip: page.ipInfo?.query || "IP Unavailable",
          countryc: page.geoInfo?.countryCode || "XX",
          countryN: page.geoInfo?.country || "Unknown",
          domainName: page.url || "URL Unavailable",
        }));

        setIpInfoData(mappedIpInfoData);
      } catch (error) {
        console.error("Error processing IP info data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (crawlResult) {
      fetchIpInfoData();
    } else {
      setIsLoading(false); // No crawl result to fetch data from
    }
  }, [crawlResult]);

  useEffect(() => {
    // Call onComplete after 5 seconds
    const timer = setTimeout(() => {
      onComplete();
    }, 10000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [onComplete]); // Dependency on onComplete

  const handleCloseWarning = () => {
    setShowWarning(false);
    return;
  };

  return (
    <>
      <div
        className={`container-fluid ${
          isBlurred || showWarning ? "blurred" : ""
        }`}
      >
        <Navbar />
        {loading || isLoading ? (
          <div className="loading-spinner">
            {/* <p>Loading, please wait...</p> */}
            <p>Exploring the digital jungle... Almost done!</p>
            <div className="spinner" />
          </div>
        ) : (
          <>
            {/* Row with five boxes */}
            <div className="row">
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">URL Name: {targetURL}</div>
              </div>
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">
                  Total Connections: {ipInfoData.length}
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">Total Tracker URL: 0</div>
              </div>
              {/* <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">TBD</div>
              </div>
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">TBD</div>
              </div>
              <div className="col-6 col-sm-6 col-md-2 mb-3">
                <div className="box">TBD</div>
              </div> */}
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12 mb-3">
                <div className="grid-item">
                  <div className="diagram">
                    <NetworkGraph
                      domainName={domainName}
                      crawlResult={crawlResult}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 mb-3">
                <div className="grid-item-two">
                  <table
                    style={{
                      margin: "15px",
                      width: "95%",
                      borderCollapse: "separate",
                      borderSpacing: "10px",
                    }}
                  >
                    <thead>
                      <tr style={{ fontSize: "1 rem" }}>
                        <th style={{ width: "10%", textAlign: "center" }}>
                          Flag
                        </th>
                        <th style={{ width: "20%", textAlign: "center" }}>
                          Country
                        </th>
                        <th style={{ width: "70%", textAlign: "left" }}>
                          Domain Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ipInfoData.length > 0 ? (
                        ipInfoData.map((loc, index) => (
                          <tr
                            key={index}
                            style={{
                              height: "25px",
                              color: "black",
                              fontSize: "0.7rem",
                            }}
                          >
                            <td style={{ textAlign: "center" }}>
                              {getCountryFlagComponent(loc.countryc)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {loc.countryN}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {loc.domainName}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showWarning && (
        <WarningModalContainer>
          <ModalContent>
            <ModalHeading>Shh !!</ModalHeading>
            <ModalMessage># Its Looking Clean</ModalMessage>
          </ModalContent>
        </WarningModalContainer>
      )}
    </>
  );
};

export default OnbordingTwo;
