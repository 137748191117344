import styled from "styled-components";
import colors from "styles/colors";
import { Card } from "components/Form/Card";
import Flag from "../misc/Flag";

const RouteRow = styled.div`
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  .ipName {
    font-size: 1rem;
  }
`;

const RouteTimings = styled.div`
  p {
    margin: 0 auto;
  }
  .arrow {
    font-size: 2.5rem;
    color: ${colors.primary};
    margin-top: -0.5rem;
  }
  .times {
    font-size: 0.85rem;
    color: ${colors.textColorSecondary};
  }
  .completed {
    text-align: center;
    font-weight: bold;
  }
`;

const cardStyles = ``;

// const TraceRouteCard = (props: { data: any, title: string, actionButtons: any }): JSX.Element => {
//   const traceRouteResponse = props.data;
//   const routes = traceRouteResponse.result;
//   return (
//     <Card heading={props.title} actionButtons={props.actionButtons} styles={cardStyles}>
//       {routes.filter((x: any) => x).map((route: any, index: number) => (
//           <RouteRow key={index}>
//             <span className="ipName">{Object.keys(route)[0]}</span>
//             <RouteTimings>
//               {route[Object.keys(route)[0]].map((time: any, packetIndex: number) => (
//                 <p className="times" key={`timing-${packetIndex}-${time}`}>
//                   { route[Object.keys(route)[0]].length > 1 && (<>Packet #{packetIndex + 1}:</>) }
//                   Took {time} ms
//                 </p>
//               ))}
//               <p className="arrow">↓</p>
//             </RouteTimings>
//           </RouteRow>
//         )
//       )}
//       <RouteTimings>
//         <p className="completed">
//           Round trip completed in {traceRouteResponse.timeTaken} ms
//         </p>
//       </RouteTimings>
//     </Card>
//   );
// }

// export default TraceRouteCard;

const TraceRouteCard = (props: {
  data: any;
  title: string;
  actionButtons: any;
  icon: any;
}): JSX.Element | null => {
  const traceRouteResponse = props.data;
  const routes = traceRouteResponse.result;
  const getCountryFlag = (countryCode: string) => {
    // Function to get flag emoji from the country code
    return countryCode
      ? `https://countryflagsapi.com/png/${countryCode}`
      : null;
  };
  if (!routes || routes === undefined || routes.length === 0) {
    return null;
  }
  return (
    <div>
      <Card
        heading={props.title}
        actionButtons={props.actionButtons}
        styles={cardStyles}
        icon={props.icon}
      >
        {routes
          .filter((x: any) => x)
          .map((route: any, index: number) => {
            const geoInfo = route.geoInfo || {};
            const country = geoInfo.country || "Unknown Country";
            const countryCode1 = geoInfo.countryCode;
            // const flagUrl = getCountryFlag(countryCode); // Get flag URL
            const time = route.time
              ? `${route.time} ms`
              : "No timing available";
            const ip = route.ip || "Unknown IP";

            return (
              <RouteRow key={index}>
                <span className="ipName">
                  {countryCode1 && (
                    <Flag countryCode={countryCode1} width={20} />
                  )}
                  <span>{"    "}</span>
                  <span> </span>
                  {ip}
                </span>
                <RouteTimings>
                  {" "}
                   
                  <p className="times">
                    {time} {/* Show the time if available */}
                  </p>
                  {/* {Array.isArray(route[Object.keys(route)[0]]) ? ( // Check if it's an array
              route[Object.keys(route)[0]].map((time: any, packetIndex: number) => (
                <p className="times" key={`timing-${packetIndex}-${time}`}>
                  {packetIndex > 0 && (<>Packet #{packetIndex + 1}:</>)}
                  Took {time} ms
                </p>
              ))
            ) : (
              // Handle object structure if needed (access specific properties)
              <p>No timing information available for this hop.</p>
            )} */}
                  <p className="arrow">↓</p>
                </RouteTimings>
              </RouteRow>
            );
          })}
        <RouteTimings>
          <p className="completed">
            Round trip completed in {traceRouteResponse.timeTaken} ms
          </p>
        </RouteTimings>
      </Card>
    </div>
  );
};

export default TraceRouteCard;
