import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { geoMercator, geoPath } from "d3-geo";
import { feature } from "topojson-client";
import { FeatureCollection } from "geojson";
import "../../styles/OnboardingOne.css";
import * as Flags from "country-flag-icons/react/3x2";
import gpsLogo from "../../assets/Gpsloc.png";
import serverpack from "../../assets/img/serverpack.png";

import Navbar from "../Navbar/Navbar";
import "../../styles/OnboardingThree.css";

interface Location {
  latitude: number;
  longitude: number;
  image?: string;
}

interface TraceRoute {
  location: string;
  ip: string;
  country: string;
  countryCode: string;
  lat: number | null;
  lon: number | null;
}

const WarningModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  font-family: "Orbitron", sans-serif;
  padding: 30px;
  box-shadow: 0px 1px 10px 5px #075283;
  border-radius: 8px;
  width: 60vw;
  text-align: center;

  @media (max-width: 480px) {
    width: 90vw; /* Even more responsive for very small screens */
    padding: 15px;
  }
  @media (orientation: landscape) and (max-width: 768px) {
    width: 40vw; /* Adjust width for landscape */
  }
`;

const ModalHeading = styled.h2`
  margin: 0 0 15px;
  font-size: 2rem;
  letter-spacing: 3px;

  @media (max-width: 768px) {
    font-size: 1.4rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Further adjust for smaller screens */
  }
`;

const ModalMessage = styled.p`
  margin: 0 0 20px;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Further adjust for smaller screens */
  }
`;

const OnboardingOne: React.FC<{
  scanId: string;
  domainName: string;
  addressType: string;
  mtrResult: any; // The API result
  loading: boolean; // The loading status
  isBlurred: boolean;
  onComplete: () => void; // Add this line
}> = ({
  scanId,
  domainName,
  addressType,
  mtrResult,
  loading,
  isBlurred,
  onComplete,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [traceRouteData, setTraceRouteData] = useState<TraceRoute[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  // console.log(locations);
  // const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    if (!mtrResult || loading) {
      // console.log("Data is still loading or mtrResult is not available");
      // setShowWarning(true);
      return;
    }
    // console.log("MTR result is:", mtrResult);

    if (mtrResult.result && mtrResult.result.length > 0) {
      const mappedLocations = mtrResult.result
        .map((loc: any) => {
          const { geoInfo } = loc;
          if (
            geoInfo &&
            !geoInfo.error &&
            geoInfo.latitude !== "N/A" &&
            geoInfo.longitude !== "N/A"
          ) {
            return {
              latitude: geoInfo.latitude,
              longitude: geoInfo.longitude,
              ip: geoInfo.ip,
              country: geoInfo.country,
              countryCode: geoInfo.countryCode,
            };
          }
          return null;
        })
        .filter((loc: any) => loc !== null);

      setLocations(mappedLocations);

      const mappedData = mtrResult.result.map((loc: any) => ({
        ip: loc.ip || "N/A",
        country:
          loc.geoInfo && loc.geoInfo.country ? loc.geoInfo.country : "Unknown",
        countryCode:
          loc.geoInfo && loc.geoInfo.countryCode
            ? loc.geoInfo.countryCode
            : null,
      }));
      setTraceRouteData(mappedData);
      setShowWarning(false);
    } else {
      // console.error("Invalid mtrResult data");
      setShowWarning(true);
      // Call onComplete after 5 seconds
      const timer = setTimeout(() => {
        onComplete();
        setShowWarning(false);
      }, 5000); // 5000 milliseconds = 5 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
      // onComplete();
    }
  }, [loading, mtrResult, onComplete]);

  useEffect(() => {
    if (locations.length === 0) {
      return;
    }

    const width = 900;
    const height = 600;
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");
    const projection = geoMercator()
      .scale(125)
      .translate([width / 2, height / 1.4]);
    const path = geoPath().projection(projection);

    svg.selectAll("*").remove();

    d3.json("https://unpkg.com/world-atlas/countries-50m.json").then(
      (data: any) => {
        const countries = feature(
          data,
          data.objects.countries
        ) as unknown as FeatureCollection;

        svg
          .selectAll(".country")
          .data(countries.features)
          .enter()
          .append("path")
          .attr("class", "country")
          .attr("d", path as any)
          .attr("fill", "#075283")
          .attr("stroke", "#fff")
          .attr("stroke-width", 0.5);

        const locationsGroup = svg.append("g").attr("class", "locations");
        locations.forEach((location, i) => {
          const [x, y] = projection([
            location.longitude,
            location.latitude,
          ]) as [number, number];
          // Check if it's the starting point

          if (i === 0) {
            locationsGroup
              .append("image")
              .attr("xlink:href", gpsLogo) // Use startingPointIcon
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
          }
          // Check if it's the ending point
          else if (i === locations.length - 1) {
            locationsGroup
              .append("image")
              .attr("xlink:href", serverpack) // Use endingPointIcon
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
          }
          // For intermediate points
          else {
            locationsGroup
              .append("image")
              .attr("xlink:href", serverpack) // Use default gpsLogo
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
          }
        });

        const linePaths: d3.Selection<
          SVGPathElement,
          unknown,
          SVGSVGElement,
          unknown
        >[] = [];
        locations.forEach((location, i) => {
          if (i < locations.length - 1) {
            const source = location;
            const target = locations[i + 1];

            const arcGenerator = (
              source: [number, number],
              target: [number, number]
            ) => {
              const dx = target[0] - source[0];
              const dy = target[1] - source[1];
              const dr = Math.sqrt(dx * dx + dy * dy);
              return `M${source[0]},${source[1]}A${dr},${dr} 0 0,1 ${target[0]},${target[1]}`;
            };

            const sourcePos = projection([
              source.longitude,
              source.latitude,
            ]) as [number, number];
            const targetPos = projection([
              target.longitude,
              target.latitude,
            ]) as [number, number];

            const linePath = svg
              .append("path")
              .attr("class", "arc")
              .attr("d", arcGenerator(sourcePos, targetPos))
              .attr("fill", "none")
              .attr("stroke", "#FF3131")
              .attr("stroke-width", 1.8)
              .attr("stroke-dasharray", "5,5");

            linePaths.push(linePath as any);
          }
        });

        if (linePaths.length > 0) {
          const planeImage = svg
            .append("image")
            .attr("xlink:href", serverpack)
            .attr("width", 30)
            .attr("height", 30)
            .attr("opacity", 0);

          const animateAlongPath = (pathIndex: number) => {
            const linePath = linePaths[pathIndex];
            const totalLength = linePath.node()?.getTotalLength() ?? 0;

            planeImage
              .attr("opacity", 1)
              .attr("transform", `translate(-15, -15)`);

            planeImage
              .transition()
              .duration(2500)
              .attrTween("transform", function () {
                return function (t: number) {
                  const point = linePath
                    .node()
                    ?.getPointAtLength(t * totalLength);
                  return point
                    ? `translate(${point.x - 15}, ${point.y - 15})`
                    : "";
                };
              })
              .ease(d3.easeLinear)
              .on("end", () => {
                if (pathIndex < linePaths.length - 1) {
                  animateAlongPath(pathIndex + 1);
                } else {
                  planeImage
                    .transition()
                    .duration(500)
                    .attr("opacity", 0)
                    .on("end", () => {
                      // Call onComplete when the animation is finished
                      onComplete();
                    });
                }
              });
          };

          animateAlongPath(0);
        }
      }
    );
  }, [locations, onComplete]);

  const getCountryFlagComponent = (countryCode: string) => {
    const FlagComponent = (Flags as any)[countryCode];
    return FlagComponent ? (
      <FlagComponent
        title={countryCode}
        style={{ width: "15px", marginRight: "5px" }}
      />
    ) : (
      <span>🏳️</span>
    );
  };
  return (
    <>
      <div
        className={`container-fluid ${
          isBlurred || showWarning ? "new-blurred" : ""
        }`}
      >
        <Navbar />
        {loading ? (
          <div className="loading-spinner">
            {/* <p>Loading, please wait...</p> */}
            <p>Sending packets on a global tour... Just a moment!</p>
            <div className="spinner" />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="header">
                  <div className="header-title">Trace Route</div>
                  <div className="header-description">
                    <p style={{ lineHeight: "1.3", letterSpacing: "0.5px" }}>
                      Trace route maps can be useful for network administrators
                      to pinpoint where delays or disruptions are occurring
                      along a network path.
                    </p>
                  </div>
                </div>
              </div>
              {/* New header on the right side */}
              <div className="col-12 col-md-4 offset-md-4">
                <div className="second-header">
                  <div className="header-title">Route Information</div>
                  <div className="header-description">
                    {traceRouteData.length > 0 ? (
                      <p>
                        Packets are transferred from{" "}
                        <strong style={{ color: "#075283" }}>
                          {traceRouteData[0]?.country || "Unknown"}
                        </strong>{" "}
                        to{" "}
                        <strong style={{ color: "#075283" }}>
                          {traceRouteData[traceRouteData.length - 1]?.country ||
                            "Unknown"}
                        </strong>
                      </p>
                    ) : (
                      <p>No route data available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <div className="map-container">
                  <svg
                    ref={svgRef}
                    className="svg"
                    preserveAspectRatio="xMidYMid meet"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 col-md-3">
                <div className="traceroutestyle">
                  <div className="header-title2">Trace Route Details</div>
                  <table style={{ width: "100%", marginTop: "10px" }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>IP</th>
                        <th style={{ textAlign: "left" }}></th>
                        <th style={{ textAlign: "left" }}>Country</th>
                      </tr>
                    </thead>
                    <tbody>
                      {traceRouteData.length > 0 ? (
                        traceRouteData.map((loc: any, index: number) => (
                          <tr key={index} style={{ height: "15px" }}>
                            <td>{loc.ip}</td>
                            <td>{getCountryFlagComponent(loc.countryCode)}</td>
                            <td>{loc.country || "Unknown"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3} style={{ textAlign: "center" }}>
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Legends */}
            <div className="row mt-4">
              <div className="col-12 col-md-3">
                <div className="legend-center-bottom">
                  <div className="legend-title">Legends</div>
                  <div className="legend-item">
                    <img
                      className="legend-icon"
                      src={gpsLogo}
                      alt="Your Location"
                    />
                    <div>Your Location</div>
                    <div className="bottomlogo-vline"></div>
                    <img
                      className="legend-icon"
                      src={serverpack}
                      alt="Server Location"
                    />
                    <div>Server Location</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Legend Container */}
            <div className="row mt-4">
              <div className="col-12 col-md-3">
                <div className="legend-container">
                  <p>
                    Visualize the trace route map of the servers that the
                    websites you are browsing are connecting with.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showWarning && (
        <WarningModalContainer>
          <ModalContent>
            <ModalHeading>Be Careful !!</ModalHeading>
            <ModalMessage># No Trace Found</ModalMessage>
          </ModalContent>
        </WarningModalContainer>
      )}
    </>
  );
};

export default OnboardingOne;
