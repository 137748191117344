import React, { useState } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import { Card } from "components/Form/Card";
import Button from "components/Form/Button";

// const CardStyles = `
// margin: 0 1rem 0 1rem;
// position: relative;
// transition: all 0.2s ease-in-out;
// display: flex;
// flex-direction: column;
// a {
//   color: ${colors.primary};
// }
// .controls {
//   display: flex;
//   flex-wrap: wrap;
//   button {
//     max-width: 350px;
//   }
// }
// small {
//   margin-top: 0.5rem;
//   font-size: 0.8rem;
//   opacity: 0.5;
// }

//   @media (max-width: 480px) {
//   min-width: 91vw !important;
//   }

//   @media (max-width: 420px) {
//     min-width: 45vh;
//     }
//       @media (max-width: 768px) {
//     min-width: 45vh;
//     margin-left: 0 1.5rem 0 1rem;
//     }
//       @media (max-width: 1500px) {
//           .controls{
//           display: flex;

//           }
//     }
// `;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const StyledIframe = styled.iframe`
  width: calc(100% - 2rem);
  outline: none;
  border: none;
  border-radius: 4px;
  min-height: 50vh;
  height: 100%;
  margin: 1rem;
  background: ${colors.background};
`;

const ViewRaw = (props: { everything: { id: string; result: any }[] }) => {
  const [resultUrl, setResultUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const makeResults = () => {
    const result: { [key: string]: any } = {};
    props.everything.forEach((item: { id: string; result: any }) => {
      result[item.id] = item.result;
    });
    return result;
  };

  const fetchResultsUrl = async () => {
    const resultContent = makeResults();
    const response = await fetch("https://jsonhero.io/api/create.json", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: "web-check results",
        content: resultContent,
        readOnly: true,
        ttl: 3600,
      }),
    });
    if (!response.ok) {
      setError(`HTTP error! status: ${response.status}`);
    } else {
      setError(null);
    }
    await response.json().then((data) => setResultUrl(data.location));
  };

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(makeResults(), null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "web-check-results.json";
    link.click();
    URL.revokeObjectURL(url);
  };
  return (
    <div style={{ margin: "0.5rem 1rem" }}>
      <Card heading="View / Download Raw Data">
        <Controls>
          {/* <div className="controls"> */}
          <Button onClick={handleDownload}>Download Results</Button>
          <Button onClick={fetchResultsUrl}>
            {resultUrl ? "Update Results" : "View Results"}
          </Button>
          {resultUrl && (
            <Button onClick={() => setResultUrl("")}>Hide Results</Button>
          )}
          {/* </div> */}
        </Controls>
        {resultUrl && !error && (
          <>
            <StyledIframe title="Results, via JSON Hero" src={resultUrl} />
            <small>
              Your results are available to view <a href={resultUrl}>here</a>.
            </small>
          </>
        )}
        {error && <p className="error">{error}</p>}
        <small>
          These are the raw results generated from your URL, and in JSON format.
          You can import these into your own program, for further analysis.
        </small>
      </Card>
    </div>
  );
};

export default ViewRaw;
